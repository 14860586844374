
import { useIntl } from "react-intl";

export const useTranslatedMessages = () => {
    const intl = useIntl();

    const tranlation_source = {
        pages: {
            home: {
                name: intl.formatMessage({ id: "pages.home.name", defaultMessage: "Home" }),
                headerContentOne: intl.formatMessage({ id: "pages.home.headerContentOne", defaultMessage: "Valuta con maggiore accuratezza il rischio di sviluppare un evento cardiovascolare maggiore (infarto, ictus) legato al danno cronico alle arterie e scopri i valori di pressione arteriosa, colesterolemia LDL e Lp(a) per proteggerti negli anni a venire!" }),
                headerContentTwo: intl.formatMessage({ id: "pages.home.headerContentTwo", defaultMessage: "Possiedi una farmacia?" }),
                headerButtonOne: intl.formatMessage({ id: "pages.home.headerButtonOne", defaultMessage: "Valuta il rischio" }),
                headerButtonTwo: intl.formatMessage({ id: "pages.home.headerButtonTwo", defaultMessage: "Recati in farmacia" }),
                headerButtonThree: intl.formatMessage({ id: "pages.home.headerButtonThree", defaultMessage: "CONTATTACI!" }),
                subHeaderTitle: intl.formatMessage({ id: "pages.home.subHeaderTitle", defaultMessage: "Le regole d’oro per il rischio cardiovascolare" }),
                cardOneTitle: intl.formatMessage({ id: "pages.home.cardOneTitle", defaultMessage: "BUONE ABITUDINI" }),
                cardOneContent: intl.formatMessage({ id: "pages.home.cardOneContent", defaultMessage: "La prevenzione della sindrome metabolica attraverso uno stile di vita sano: attività fisica regolare, abolizione del fumo di sigaretta, dieta ipolipidica, iposodica, ricca di potassio e ipocalorica (nel caso del sovrappeso/obesità) rallenta la progressione del danno aterogeno alle arterie" }),
                cardTwoTitle: intl.formatMessage({ id: "pages.home.cardTwoTitle", defaultMessage: "PREVENZIONE" }),
                cardTwoContent: intl.formatMessage({ id: "pages.home.cardTwoContent", defaultMessage: "Prevenire l’esposizione ai fattori di rischio permette di vivere in salute e a lungo. Se sei esposto a fattori di rischio (quali ipertensione arteriosa, dislipidemia, abitudine al fumo di sigaretta, etc.) abbiamo strategie efficaci per prevenire lo sviluppo di malattie cardiovascolari mortali ed invalidanti" }),
                cardThreeTitle: intl.formatMessage({ id: "pages.home.cardThreeTitle", defaultMessage: "MONITORAGGIO" }),
                cardThreeContent: intl.formatMessage({ id: "pages.home.cardThreeContent", defaultMessage: "Sottoporsi a controlli periodici di pressione arteriosa e livelli di colesterolo è raccomandato per diagnosticare precocemente condizioni che possano mettere in pericolo cuore e cervello." }),
                cardFourTitle: intl.formatMessage({ id: "pages.home.cardFourTitle", defaultMessage: "ONLINE" }),
                cardFourContent: intl.formatMessage({ id: "pages.home.cardFourContent", defaultMessage: "Compila gratuitamente la scala e ricevi il report sul tuo rischio cardiovascolare da far visionare al medico curante. Per scaricare il report devi compilare correttamente tutti i campi obbligatori!" }),
                cardFiveTitle: intl.formatMessage({ id: "pages.home.cardFiveTitle", defaultMessage: "IN FARMACIA" }),
                cardFiveContent: intl.formatMessage({ id: "pages.home.cardFiveContent", defaultMessage: "Recati presso una delle farmacie autorizzate e ottieni un report dettagliato e personalizzato sul tuo rischio cardiovascolare aggiungendo altre informazioni e referti" }),
                continueRead: intl.formatMessage({ id: "pages.home.continueRead", defaultMessage: "Continua a leggere" }),
                mapTitle: intl.formatMessage({ id: "pages.home.mapTitle", defaultMessage: "Le nostre farmacie autorizzate" }),
                mapClickButtonName: intl.formatMessage({ id: "pages.home.mapClickButtonName", defaultMessage: "Apri in Maps" }),
                mapContent: intl.formatMessage({ id: "pages.home.mapContent", defaultMessage: "Scopri la farmacia autorizzata a te più vicina." }),
                mapContent2: intl.formatMessage({ id: "pages.home.mapContent2", defaultMessage: "Lavori presso una farmacia?" }),
                mapContent3: intl.formatMessage({ id: "pages.home.mapContent3", defaultMessage: "CONTATTACI" }),
                mapContent4: intl.formatMessage({ id: "pages.home.mapContent4", defaultMessage: "per aderire al servizio" }),
                topPharmaciesTitle: intl.formatMessage({ id: "pages.home.topPharmaciesTitle", defaultMessage: "Farmacie in zona" }),
                contributorContent: intl.formatMessage({ id: "pages.home.contributorContent", defaultMessage: "Con il contributo incondizionato di:" }),
                mapPositionButton: intl.formatMessage({ id: "pages.home.mapPositionButton", defaultMessage: "Posizione attuale" }),
            }
            ,
            aboutUs: {
                name: intl.formatMessage({ id: "pages.aboutUs.name", defaultMessage: "Chi Siamo" }),
                title: intl.formatMessage({ id: "pages.aboutUs.title", defaultMessage: "Who We Are" }),
                subTitleContent: intl.formatMessage({ id: "pages.aboutUs.subTitleContent", defaultMessage: "Piattaforma umanizzata per il calcolo del rischio cardiovascolare e la prevenzione delle malattie cardiovascolari in collaborazione con il Centro di riferimento della Regione Marche per l’Ipertensione Arteriosa, le Malattie Cardiovascolari e le Dislipidemie, Hypertension Excellence Centre della European Society of Hypertension (ESH) e Centro LIPIGEN della Società Italiana per lo Studio dell’Aterosclerosi (SISA), Clinica Medica e Geriatrica IRCCS INRCA di Ancona, Università Politecnica delle Marche" }),
                fraseOneMaps: intl.formatMessage({ id: "pages.aboutUs.fraseOneMaps", defaultMessage: "HUMTELEMED SRL Società Benefit" }),
                fraseTwoMaps: intl.formatMessage({ id: "pages.aboutUs.fraseTwoMaps", defaultMessage: "Via Tronto 10/A, Ancona, ITALIA" }),
                fraseThreeMaps: intl.formatMessage({ id: "pages.aboutUs.fraseThreeMaps", defaultMessage: "Affiliazioni:" }),
                affiliationOne: intl.formatMessage({ id: "pages.aboutUs.affiliationOne", defaultMessage: "CENTRO IPERTENSIONE ARTERIOSA E MALATTIE CARDIOVASCOLARI" }),
                affiliationTwo: intl.formatMessage({ id: "pages.aboutUs.affiliationTwo", defaultMessage: "Centro di riferimento per la Regione Marche ESH European Excellence Centre" }),
                affiliationThree: intl.formatMessage({ id: "pages.aboutUs.affiliationThree", defaultMessage: "Centro LIPIGEN della SISA per lo studio delle dislipidemie. Il responsabile del centro è il Prof. Riccardo Sarzani" }),
                affiliationFor: intl.formatMessage({ id: "pages.aboutUs.affiliationFor", defaultMessage: "Clinica Medica e Geriatrica IRCCS INRCA di Ancona" }),
                maps_button: intl.formatMessage({ id: "pages.aboutUs.maps_button", defaultMessage: "Apri in Maps" }),
            }
            ,
            reservedArea: {
                name: intl.formatMessage({ id: "pages.reservedArea.name", defaultMessage: "Area Riservata" }),
                addCompilationDialogHeader: intl.formatMessage({ id: "pages.reservedArea.addCompilationDialogHeader", defaultMessage: "Nuova compilazione" }),
                addCompilationDialogClient: intl.formatMessage({ id: "pages.reservedArea.addCompilationDialogClient", defaultMessage: "Paziente" }),
                addCompilationDialogClientHelp: intl.formatMessage({ id: "pages.reservedArea.addCompilationDialogClientHelp", defaultMessage: "Non selezionare il paziente se si desidera compilare una scala anonima" }),
                addCompilationDialogScale: intl.formatMessage({ id: "pages.reservedArea.addCompilationDialogScale", defaultMessage: "Scala" }),
                addCompilationDialogButton: intl.formatMessage({ id: "pages.reservedArea.addCompilationDialogButton", defaultMessage: "Conferma" }),
                removeSelectedItem: intl.formatMessage({ id: "pages.reservedArea.removeSelectedItem", defaultMessage: "Annulla selezione" }),
            }
            ,
            reservedAreaRecent: {
                name: intl.formatMessage({ id: "pages.reservedAreaRecent.name", defaultMessage: "Recenti" }),
                tableTitle: intl.formatMessage({ id: "pages.reservedAreaRecent.tableTitle", defaultMessage: "Scale Compilate" }),
            }
            ,
            reservedAreaClients: {
                name: intl.formatMessage({ id: "pages.reservedAreaClients.name", defaultMessage: "Pazienti" }),
                tableTitle: intl.formatMessage({ id: "pages.reservedAreaClients.tableTitle", defaultMessage: "Pazienti registrati" }),
                addCompilationDialogHeader: intl.formatMessage({ id: "pages.reservedAreaClients.addCompilationDialogHeader", defaultMessage: "Nuova registrazione" }),
                updateCompilationDialogHeader: intl.formatMessage({ id: "pages.reservedAreaClients.updateCompilationDialogHeader", defaultMessage: "Modifica Paziente" }),
                addCompilationDialogEmail: intl.formatMessage({ id: "pages.reservedAreaClients.addCompilationDialogEmail", defaultMessage: "Email" }),
                addCompilationDialogName: intl.formatMessage({ id: "pages.reservedAreaClients.addCompilationDialogName", defaultMessage: "Nome" }),
                addCompilationDialogLastName: intl.formatMessage({ id: "pages.reservedAreaClients.addCompilationDialogLastName", defaultMessage: "Cognome" }),
                addCompilationDialogDateOfBirth: intl.formatMessage({ id: "pages.reservedAreaClients.addCompilationDialogDateOfBirth", defaultMessage: "Data di nascita" }),
                addCompilationDialogNationality: intl.formatMessage({ id: "pages.reservedAreaClients.addCompilationDialogNationality", defaultMessage: "Nazionalità" }),
                addCompilationDialogCityOfBirth: intl.formatMessage({ id: "pages.reservedAreaClients.addCompilationDialogCityOfBirth", defaultMessage: "Città di nascita" }),
                addCompilationDialogProvinceOfBirth: intl.formatMessage({ id: "pages.reservedAreaClients.addCompilationDialogProvinceOfBirth", defaultMessage: "Provincia di nascita" }),
                addCompilationDialogTaxCode: intl.formatMessage({ id: "pages.reservedAreaClients.addCompilationDialogTaxCode", defaultMessage: "Codice Fiscale" }),
                addCompilationDialogButton: intl.formatMessage({ id: "pages.reservedAreaClients.addCompilationDialogButton", defaultMessage: "Conferma" }),
            }
            ,
            reservedAreaProfile: {
                name: intl.formatMessage({ id: "pages.reservedAreaProfile.name", defaultMessage: "Profilo" }),
                assistenceButton: intl.formatMessage({ id: "pages.reservedAreaProfile.assistenceButton", defaultMessage: "Assistenza" }),
                addCompilationDialogHeader: intl.formatMessage({ id: "pages.reservedAreaProfile.addCompilationDialogHeader", defaultMessage: "Nuovo Utente" }),
                updateCompilationDialogHeader: intl.formatMessage({ id: "pages.reservedAreaProfile.updateCompilationDialogHeader", defaultMessage: "Modifica utente" }),
                detailsText: intl.formatMessage({ id: "pages.reservedAreaProfile.detailsText", defaultMessage: "Dettagli" }),
                usersText: intl.formatMessage({ id: "pages.reservedAreaProfile.usersText", defaultMessage: "Utenti" }),
                addButton: intl.formatMessage({ id: "pages.reservedAreaProfile.addButton", defaultMessage: "Aggiungi" }),
                updateButton: intl.formatMessage({ id: "pages.reservedAreaProfile.updateButton", defaultMessage: "Modifica" }),
                deleteButton: intl.formatMessage({ id: "pages.reservedAreaProfile.deleteButton", defaultMessage: "Cancella" }),
                addCompilationDialogEmail: intl.formatMessage({ id: "pages.reservedAreaProfile.addCompilationDialogEmail", defaultMessage: "Email" }),
                addCompilationDialogName: intl.formatMessage({ id: "pages.reservedAreaProfile.addCompilationDialogName", defaultMessage: "Name" }),
                addCompilationDialogRole: intl.formatMessage({ id: "pages.reservedAreaProfile.addCompilationDialogRole", defaultMessage: "Ruolo" }),
                addCompilationDialogButton: intl.formatMessage({ id: "pages.reservedAreaProfile.addCompilationDialogButton", defaultMessage: "Conferma" }),
            }
            ,
            authorized: {
                logged_page_: intl.formatMessage({ id: "pages.authorized.logged_page_", defaultMessage: "Scale disponibili" }),
                logged_page_profile: intl.formatMessage({ id: "pages.authorized.logged_page_profile", defaultMessage: "Profilo" }),
            }
            ,
            profile: {
                profileNameLabel: intl.formatMessage({ id: "pages.profile.profileNameLabel", defaultMessage: "Nome" }),
                profileEmailLabel: intl.formatMessage({ id: "pages.profile.profileEmailLabel", defaultMessage: "Email" }),
                profilePharmacyAddressLabel: intl.formatMessage({ id: "pages.profile.profilePharmacyAddressLabel", defaultMessage: "Indirizzo" }),
                profilePharmacyCityLabel: intl.formatMessage({ id: "pages.profile.profilePharmacyCityLabel", defaultMessage: "Città" }),
                profilePharmacyWebsiteLabel: intl.formatMessage({ id: "pages.profile.profilePharmacyWebsiteLabel", defaultMessage: "Sito web" }),
                profilePharmacyVATLabel: intl.formatMessage({ id: "pages.profile.profilePharmacyVATLabel", defaultMessage: "P. IVA" }),
                profilePharmacyPECLabel: intl.formatMessage({ id: "pages.profile.profilePharmacyPECLabel", defaultMessage: "PEC" }),
                profileSettingsSkipTutorialLabel: intl.formatMessage({ id: "pages.profile.profileSettingsSkipTutorialLabel", defaultMessage: "Salta il tutorial" }),
                profileDialogReport: intl.formatMessage({ id: "pages.profile.profileDialogReport", defaultMessage: "Scrivi qui il tuo problema" }),
                profileReport: intl.formatMessage({ id: "pages.profile.profileReport", defaultMessage: "Segnalazione" }),
                typeReport: intl.formatMessage({ id: "pages.profile.typeReport", defaultMessage: "Tipo di segnalazione" }),
                sendReportServerError: intl.formatMessage({ id: "pages.profile.sendReportServerError", defaultMessage: "Si è verificato un errore nell'invio della richiesta. Riprova più tardi." }),
            }
            ,
            partner: {
                partner: intl.formatMessage({ id: "pages.partner.partner", defaultMessage: "With the unconditional contribution of:" }),
            }
            ,
            resetPassword: {
                resetPasswordPageTitle: intl.formatMessage({ id: "pages.resetPassword.resetPasswordPageTitle", defaultMessage: "Reset password" }),
                resetPasswordPagePasswordLabel: intl.formatMessage({ id: "pages.resetPassword.resetPasswordPagePasswordLabel", defaultMessage: "Nuova password" }),
                resetPasswordPageConfirmPasswordLabel: intl.formatMessage({ id: "pages.resetPassword.resetPasswordPageConfirmPasswordLabel", defaultMessage: "Conferma password" }),
                resetPasswordPageSubmitButtonLabel: intl.formatMessage({ id: "pages.resetPassword.resetPasswordPageSubmitButtonLabel", defaultMessage: "Conferma password" }),
                resetPaswordPageEqualityMessage: intl.formatMessage({ id: "pages.resetPassword.resetPaswordPageEqualityMessage", defaultMessage: "Le password devono essere uguali" }),
                resetPasswordRequested: intl.formatMessage({ id: "pages.resetPassword.resetPasswordRequested", defaultMessage: "La richiesta è avvenuta con successo. Riceverai a breve una mail che potrai usare per reimpostare la password." }),
                errorResetPasswordFailed: intl.formatMessage({ id: "pages.resetPassword.errorResetPasswordFailed", defaultMessage: "Reset della password non riuscita! Assicurati che la sessione non sia scaduta." }),
                generalErrorResetPassword: intl.formatMessage({ id: "pages.resetPassword.generalErrorResetPassword", defaultMessage: "Si è verificato un problema con l'elaborazione  della richiesta. Riprova o contattaci." }),
            }
            ,
            changePassword: {
                oldPasswordLabel: intl.formatMessage({ id: "pages.changePassword.oldPasswordLabel", defaultMessage: "Vecchia password" }),
                resetPasswordPageOldPasswordError: intl.formatMessage({ id: "pages.changePassword.resetPasswordPageOldPasswordError", defaultMessage: "Il campo della vecchia password non può essere vuoto!" }),
                resetPasswordPagePasswordError: intl.formatMessage({ id: "pages.changePassword.resetPasswordPagePasswordError", defaultMessage: "La password non è sufficientemente sicura. Assicurati che la password sia lunga 8 caratteri, con almeno una lettera maiuscola, un numero e un carattere speciale!" }),
                resetPasswordPageEqualityPasswordError: intl.formatMessage({ id: "pages.changePassword.resetPasswordPageEqualityPasswordError", defaultMessage: "Le password non coincidono!" }),
                errorChangePassword: intl.formatMessage({ id: "pages.changePassword.errorChangePassword", defaultMessage: "La procedura non è andata a buon fine. Assicurati di aver inserito i dati correttamente." }),
                generalErrorChangePassword: intl.formatMessage({ id: "pages.changePassword.generalErrorChangePassword", defaultMessage: "Si è verificato un errore durante il login. Riprova piu tardi oppure contattaci se il problema persiste." }),
            }
            ,
            tutorial: {
                tutorialIntro: intl.formatMessage({ id: "pages.tutorial.tutorialIntro", defaultMessage: "Introduzione" }),
                tutorialTitle1: intl.formatMessage({ id: "pages.tutorial.tutorialTitle1", defaultMessage: "Cos’è il rischio cardiovascolare?" }),
                tutorialText1: intl.formatMessage({ id: "pages.tutorial.tutorialText1", defaultMessage: "La pressione arteriosa, i livelli di colesterolo o il fumo di sigaretta sono tra i fattori che determinano il rischio cardiovascolare. Qui puoi calcolare gratuitamente il tuo rischio inserendo semplici dati clinici." }),
                tutorialTitle2: intl.formatMessage({ id: "pages.tutorial.tutorialTitle2", defaultMessage: "Più funzionalità personalizzate" }),
                tutorialText2: intl.formatMessage({ id: "pages.tutorial.tutorialText2", defaultMessage: "Puoi ottenere una valutazione dettagliata e certificata del tuo rischio cardiovascolare accedendo a funzioni aggiuntive a pagamento (ancora in allestimento)." }),
                tutorialTitle3: intl.formatMessage({ id: "pages.tutorial.tutorialTitle3", defaultMessage: "Qualità certificata" }),
                tutorialText3: intl.formatMessage({ id: "pages.tutorial.tutorialText3", defaultMessage: "Il servizio è svolto da operatori sanitari esperti nella valutazione del rischio cardiovascolare afferenti al Centro per l’Ipertensione Arteriosa e le Malattie Cardiovascolari di Ancona - Clinica medica e geriatrica UNIVPM IRCCS-INRCA (centro di eccellenza della ESH European Hypertension Society e Centro LIPIGEN per le dislipidemie della SISA Società Italiana Studio Aterosclerosi)." }),
            }
            ,
            privacy: {
                name: intl.formatMessage({ id: "pages.privacy.name", defaultMessage: "Privacy" }),
                privacyIntroBody: intl.formatMessage({ id: "pages.privacy.privacyIntroBody", defaultMessage: "Il Calcolatore per il rischio cardiovascolare  fornito da HUMTELEMED restituisce dati a titolo puramente informativo. Il Calcolatore è stato pubblicato con l'intento di essere utile, ma senza alcuna garanzia, implicita o esplicita, di idoneità allo scopo. Il Calcolatore non deve essere considerato come una diagnosi definitiva o una raccomandazione ad adottare un'azione o un trattamento specifico. Le informazioni generate al calcolatore non sostituiscono la consulenza o l'assistenza medica professionale fornita da un medico qualificato o da un altro operatore sanitario. Consultare sempre il proprio medico se si hanno dubbi sulla propria salute, su qualsiasi condizione o trattamento. Non ignorare mai il parere di un medico professionista né ritardare la sua richiesta a causa delle informazioni generate dal Calcolatore o altre informazioni presenti nel sito web." }),
                privacyLiabilityTItle: intl.formatMessage({ id: "pages.privacy.privacyLiabilityTItle", defaultMessage: "RINUNCIA ALLA RESPONSABILITÀ" }),
                privacyLiabilityBody: intl.formatMessage({ id: "pages.privacy.privacyLiabilityBody", defaultMessage: "L'uso del Calcolatore, dei risultati generati dal Calcolatore e delle informazioni contenute e collegate a questo sito web è a rischio dell'utente. Nonostante la cura esercitata dagli autori nella compilazione del Calcolatore e di questo sito web, sono possibili errori e omissioni. Per questo motivo, HUMTELEMED non può assumersi alcuna responsabilità nei confronti di terzi. Il Calcolatore e il sito web HUMTELEMED sono forniti 'così come sono' e 'come disponibili' senza alcuna dichiarazione o approvazione e HUMTELEMED non fornisce alcun tipo di garanzia, espressa o implicita, in relazione al Calcolatore e/o al sito web HUMTELEMED, comprese, a titolo esemplificativo e non esaustivo, le garanzie implicite di qualità soddisfacente, idoneità per uno scopo particolare, non violazione, compatibilità, sicurezza, accuratezza, condizioni di completezza o qualsiasi garanzia implicita derivante dal corso dei rapporti o dagli usi. HUMTELEMED non garantisce che il Calcolatore soddisferà le esigenze dell'utente o saranno ininterrotti, tempestivi, sicuri o privi di errori, che i difetti saranno corretti o che il Calcolatore o il sito Web o il server che li rende disponibili siano privi di virus o bug o che rappresentino la piena funzionalità, accuratezza e affidabilità dei materiali. HUMTELEMED non sarà responsabile di eventuali perdite di contenuti o materiali caricati o trasmessi attraverso il Sito." }),
                privacyIndemnityTitle: intl.formatMessage({ id: "pages.privacy.privacyIndemnityTitle", defaultMessage: "INDENNIZZO" }),
                privacyIndemnityBody: intl.formatMessage({ id: "pages.privacy.privacyIndemnityBody", defaultMessage: "L'utente accetta di indennizzare HUMTELEMED e i suoi funzionari, direttori e dipendenti, immediatamente su richiesta, contro tutti i reclami, le responsabilità, i danni, i costi e le spese, comprese le spese legali, derivanti da qualsiasi violazione dei presenti Termini e condizioni da parte dell'utente o da qualsiasi altra responsabilità derivante dall'uso del Calcolatore e/o del presente Sito web." }),
                privacyCopyrightTitle: intl.formatMessage({ id: "pages.privacy.privacyCopyrightTitle", defaultMessage: "COPYRIGHT" }),
                privacyCopyrightBody: intl.formatMessage({ id: "pages.privacy.privacyCopyrightBody", defaultMessage: "Tutti i diritti sono riservati. I materiali presenti in questo sito web sono protetti dalla legge sul copyright. Nulla di questo sito web può essere riprodotto, salvato in un database automatico o pubblicato in qualsiasi modo o forma senza il previo consenso scritto di HUMTELEMED." }),
                privacyRightsTitle: intl.formatMessage({ id: "pages.privacy.privacyRightsTitle", defaultMessage: "DIRITTI DI HUMTELEMED" }),
                privacyRightsBody: intl.formatMessage({ id: "pages.privacy.privacyRightsBody", defaultMessage: "HUMTELEMED si riserva il diritto di modificare o ritirare, temporaneamente o permanentemente, del Calcolatore e/o il Sito web (o parte di esso) con o senza preavviso all'utente e l'utente conferma che HUMTELEMED non sarà responsabile nei confronti dell'utente o di terzi per qualsiasi modifica o ritiro del Calcolatore e/o del Sito web. HUMTELEMED si riserva il diritto di modificare di volta in volta i Termini e le Condizioni in base ai quali vengono offerti il Calcolatore e il Sito web. Tali modifiche saranno visualizzate sul sito web. L'uso continuato del Calcolatore e/o del Sito web (o di qualsiasi parte di esso) da parte dell'utente in seguito a tali modifiche sarà considerato come accettazione di tali modifiche. L'utente deve assicurarsi di visitare regolarmente il Sito web, in quanto è l'unico responsabile della conoscenza delle modifiche apportate ai Termini e condizioni." }),
                privacyLawTitle: intl.formatMessage({ id: "pages.privacy.privacyLawTitle", defaultMessage: "LEGGE APPLICABILE" }),
                privacyLawBody: intl.formatMessage({ id: "pages.privacy.privacyLawBody", defaultMessage: "I presenti Termini e Condizioni sono disciplinati dalle leggi italiane. L'utente acconsente alla giurisdizione esclusiva dei tribunali italiani per tutte le controversie derivanti da o relative all'uso del Calcolatore e di questo Sito web. L'uso del Calcolatore e di questo Sito Web non è autorizzato in nessuna giurisdizione che non dia effetto a tutte le disposizioni di questi Termini e Condizioni, incluso, senza limitazione, questo paragrafo. Se una qualsiasi parte del presente accordo è ritenuta non valida o non applicabile ai sensi della legge applicabile, comprese, ma non solo, le esclusioni di garanzia e le limitazioni di responsabilità di cui sopra, la disposizione non valida o inapplicabile sarà considerata sostituita da una disposizione valida e applicabile che si avvicina il più possibile all'intento della disposizione originale e la parte restante dell'accordo continuerà ad essere valida." }),
                privacyPolicyTitle: intl.formatMessage({ id: "pages.privacy.privacyPolicyTitle", defaultMessage: "INFORMATIVA SULLA PRIVACY" }),
                privacyPolicyBody: intl.formatMessage({ id: "pages.privacy.privacyPolicyBody", defaultMessage: "Previo consenso informato, tutti i dati inseriti in questo sito web, utili al calcolo del rischio cardiovascolare, saranno conservati in forma anonima in un database per analisi statistiche e scopi di ricerca. Altri dati inseriti in questo sito non saranno memorizzati." }),
            }
            ,
            error: {
                ops: intl.formatMessage({ id: "pages.error.ops", defaultMessage: "Ops" }),
                messageErrorPage: intl.formatMessage({ id: "pages.error.messageErrorPage", defaultMessage: "C'è stato un errore. Se il problema persiste contattaci." }),
                backToHome: intl.formatMessage({ id: "pages.error.backToHome", defaultMessage: "Ritorna alla Home" }),
            }
            ,
        }
        ,
        scales: {
            shared: {
                riskLevels: {
                    extreme: intl.formatMessage({ id: "scales.shared.riskLevels.extreme", defaultMessage: "Estremo" }),
                    veryHigh: intl.formatMessage({ id: "scales.shared.riskLevels.veryHigh", defaultMessage: "Molto alto" }),
                    high: intl.formatMessage({ id: "scales.shared.riskLevels.high", defaultMessage: "Alto" }),
                    moderateLow: intl.formatMessage({ id: "scales.shared.riskLevels.moderateLow", defaultMessage: "Basso-Moderato" }),
                }
                ,
                eventProbabilityMatrix: {
                    m11: intl.formatMessage({ id: "scales.shared.eventProbabilityMatrix.m11", defaultMessage: "inferiore al 2.5%" }),
                    m12: intl.formatMessage({ id: "scales.shared.eventProbabilityMatrix.m12", defaultMessage: "inferiore al 5%" }),
                    m13: intl.formatMessage({ id: "scales.shared.eventProbabilityMatrix.m13", defaultMessage: "inferiore al 7.5%" }),
                    m21: intl.formatMessage({ id: "scales.shared.eventProbabilityMatrix.m21", defaultMessage: "compresa tra il 2.5% e il 7.5%" }),
                    m22: intl.formatMessage({ id: "scales.shared.eventProbabilityMatrix.m22", defaultMessage: "compresa tra il 5% e il 10%" }),
                    m23: intl.formatMessage({ id: "scales.shared.eventProbabilityMatrix.m23", defaultMessage: "compresa tra il 7.5% e il 15%" }),
                    m31: intl.formatMessage({ id: "scales.shared.eventProbabilityMatrix.m31", defaultMessage: "maggiore del 7.5%" }),
                    m32: intl.formatMessage({ id: "scales.shared.eventProbabilityMatrix.m32", defaultMessage: "maggiore del 10%" }),
                    m33: intl.formatMessage({ id: "scales.shared.eventProbabilityMatrix.m33", defaultMessage: "maggiore del 15%" }),
                    m41: intl.formatMessage({ id: "scales.shared.eventProbabilityMatrix.m41", defaultMessage: "maggiore del 20%" }),
                    m42: intl.formatMessage({ id: "scales.shared.eventProbabilityMatrix.m42", defaultMessage: "maggiore del 20%" }),
                    m43: intl.formatMessage({ id: "scales.shared.eventProbabilityMatrix.m43", defaultMessage: "maggiore del 20%" }),
                }
                ,
                forms: {
                    privacy1: intl.formatMessage({ id: "scales.shared.forms.privacy1", defaultMessage: "Ho letto l'" }),
                    privacyLink: intl.formatMessage({ id: "scales.shared.forms.privacyLink", defaultMessage: "informativa sulla privacy" }),
                    formError: intl.formatMessage({ id: "scales.shared.forms.formError", defaultMessage: "Uno o più campi non sono corretti" }),
                    mandatoryFields: intl.formatMessage({ id: "scales.shared.forms.mandatoryFields", defaultMessage: "I campi con (*) sono obbligatori" }),
                    calculate: intl.formatMessage({ id: "scales.shared.forms.calculate", defaultMessage: "Calcola" }),
                    dataNotSavedInCloudButPrinted: intl.formatMessage({ id: "scales.shared.forms.dataNotSavedInCloudButPrinted", defaultMessage: "Dato non memorizzato, inserire solo se si desidera riportarlo nel report PDF" }),
                }
                ,
            }
            ,
            scaleFree: {
                scaleName: intl.formatMessage({ id: "scales.scaleFree.scaleName", defaultMessage: "Compila la nostra scala gratuita" }),
                subtitle: intl.formatMessage({ id: "scales.scaleFree.subtitle", defaultMessage: "Scala approfondita" }),
            }
            ,
            lopreHolder: {
                scaleName: intl.formatMessage({ id: "scales.lopreHolder.scaleName", defaultMessage: "Compila la nostra scala per il paziente" }),
                subtitle: intl.formatMessage({ id: "scales.lopreHolder.subtitle", defaultMessage: "Scala per le farmacie" }),
            }
            ,
            scaleGlycated: {
                scaleName: intl.formatMessage({ id: "scales.scaleGlycated.scaleName", defaultMessage: "Compila la nostra scala per il paziente" }),
                subtitle: intl.formatMessage({ id: "scales.scaleGlycated.subtitle", defaultMessage: "Scala per le farmacie" }),
            }
            ,
            scaleWithComponentShared: {
                creatinine: {
                    label: intl.formatMessage({ id: "scales.scaleWithComponentShared.creatinine.label", defaultMessage: "Creatinina" }),
                    errorText: intl.formatMessage({ id: "scales.scaleWithComponentShared.creatinine.errorText", defaultMessage: "Il valore deve essere compreso tra 0,1 e 10,3" }),
                }
                ,
                familyHychol: {
                    label: intl.formatMessage({ id: "scales.scaleWithComponentShared.familyHychol.label", defaultMessage: "Hai un'ipercolesterolemia familiare genetica diagnosticata con certezza?" }),
                    textInfo: intl.formatMessage({ id: "scales.scaleWithComponentShared.familyHychol.textInfo", defaultMessage: "L’ipercolesterolemia familiare (FH) è una malattia ereditaria in cui la mutazione del gene che codifica per il recettore delle LDL provoca livelli estremamente elevati di colesterolo circolante. Il recettore per le LDL alterato perde la capacità di “catturare” le particelle di colesterolo LDL, rendendone più difficile la rimozione dal sangue. L’ipercolesterolemia familiare provoca una prolungata esposizione ad elevati livelli di colesterolo LDL, rappresentando un importante causa di malattia coronarica precoce." }),
                }
                ,
                diabete2: {
                    label: intl.formatMessage({ id: "scales.scaleWithComponentShared.diabete2.label", defaultMessage: "Hai il diabete mellito di tipo 2?" }),
                }
                ,
            }
            ,
            registryComponent: {
                title: intl.formatMessage({ id: "scales.registryComponent.title", defaultMessage: "Dati anagrafici" }),
                name: {
                    label: intl.formatMessage({ id: "scales.registryComponent.name.label", defaultMessage: "Nome" }),
                    textError: intl.formatMessage({ id: "scales.registryComponent.name.textError", defaultMessage: "Il nome è obbligatorio" }),
                }
                ,
                surname: {
                    label: intl.formatMessage({ id: "scales.registryComponent.surname.label", defaultMessage: "Cognome" }),
                    textError: intl.formatMessage({ id: "scales.registryComponent.surname.textError", defaultMessage: "Il cognome è obbligatorio" }),
                }
                ,
                country: {
                    label: intl.formatMessage({ id: "scales.registryComponent.country.label", defaultMessage: "Nazione di provienenza" }),
                    errorText: intl.formatMessage({ id: "scales.registryComponent.country.errorText", defaultMessage: "La scelta della nazione di provienenza è obbligatoria" }),
                }
                ,
                sex: {
                    label: intl.formatMessage({ id: "scales.registryComponent.sex.label", defaultMessage: "Sesso" }),
                    errorText: intl.formatMessage({ id: "scales.registryComponent.sex.errorText", defaultMessage: "La scelta del sesso è obbligatoria" }),
                    male: intl.formatMessage({ id: "scales.registryComponent.sex.male", defaultMessage: "Maschio" }),
                    female: intl.formatMessage({ id: "scales.registryComponent.sex.female", defaultMessage: "Femmina" }),
                }
                ,
                age: {
                    label: intl.formatMessage({ id: "scales.registryComponent.age.label", defaultMessage: "Età" }),
                    errorText: intl.formatMessage({ id: "scales.registryComponent.age.errorText", defaultMessage: "Il valore deve essere intero e compreso tra 40 e 89" }),
                    infoText: intl.formatMessage({ id: "scales.registryComponent.age.infoText", defaultMessage: "Le carte del rischio con le quali si stima il rischio cardiovascolare si basano sui dati raccolti da pazienti di età compresa tra i 40 e i 89 anni. Quindi per soggetti con età non compresa in questo intervallo non è possibile fornire una stima automatica." }),
                    hintText: intl.formatMessage({ id: "scales.registryComponent.age.hintText", defaultMessage: "L'età deve essere intero e compresa tra i 40 e i 89 anni" }),
                }
                ,
            }
            ,
            infoBodyComponent: {
                title: intl.formatMessage({ id: "scales.infoBodyComponent.title", defaultMessage: "Dati fisici" }),
                height: {
                    label: intl.formatMessage({ id: "scales.infoBodyComponent.height.label", defaultMessage: "Altezza" }),
                    errorText: intl.formatMessage({ id: "scales.infoBodyComponent.height.errorText", defaultMessage: "Il valore deve essere intero e compreso tra 60 e 220" }),
                }
                ,
                weight: {
                    label: intl.formatMessage({ id: "scales.infoBodyComponent.weight.label", defaultMessage: "Peso" }),
                    errorText: intl.formatMessage({ id: "scales.infoBodyComponent.weight.errorText", defaultMessage: "Il valore deve essere intero e compreso tra 30 e 200" }),
                }
                ,
            }
            ,
            smokeComponent: {
                title: intl.formatMessage({ id: "scales.smokeComponent.title", defaultMessage: "Dati Fumatore" }),
                actualSmoker: {
                    label: intl.formatMessage({ id: "scales.smokeComponent.actualSmoker.label", defaultMessage: "Fumatore attivo" }),
                }
                ,
                exSmoker: {
                    label: intl.formatMessage({ id: "scales.smokeComponent.exSmoker.label", defaultMessage: "Ex fumatore" }),
                    errorText: intl.formatMessage({ id: "scales.smokeComponent.exSmoker.errorText", defaultMessage: "Il valore deve essere intero e compreso tra 0 e 89" }),
                    infoText: intl.formatMessage({ id: "scales.smokeComponent.exSmoker.infoText", defaultMessage: "Inserire 0 se hai smesso da meno di un anno o fumi attualmente" }),
                    exSmokerYears: intl.formatMessage({ id: "scales.smokeComponent.exSmoker.exSmokerYears", defaultMessage: "Da quanti anni non fumi?" }),
                }
                ,
            }
            ,
            pressureComponent: {
                title: intl.formatMessage({ id: "scales.pressureComponent.title", defaultMessage: "Dati Pressione" }),
                systolicPressure: {
                    label: intl.formatMessage({ id: "scales.pressureComponent.systolicPressure.label", defaultMessage: "Pressione Sistolica" }),
                    errorText: intl.formatMessage({ id: "scales.pressureComponent.systolicPressure.errorText", defaultMessage: "Il valore deve essere intero e maggiore di 85  e minore di 300" }),
                    infoText: intl.formatMessage({ id: "scales.pressureComponent.systolicPressure.infoText", defaultMessage: "Inserire valori medi tra quelli misurati correttamente di recente a domicilio. Il rischio viene valutato attraverso la Pressione Arteriosa Sistolica prima del trattamento." }),
                    hintText: intl.formatMessage({ id: "scales.pressureComponent.systolicPressure.hintText", defaultMessage: "Valori medi tra quelli misurati di recente dal medico" }),
                }
                ,
                diastolicPressure: {
                    label: intl.formatMessage({ id: "scales.pressureComponent.diastolicPressure.label", defaultMessage: "Pressione Diastolica" }),
                    errorText: intl.formatMessage({ id: "scales.pressureComponent.diastolicPressure.errorText", defaultMessage: "Il valore deve essere intero e maggiore di 50  e minore di 300" }),
                    infoText: intl.formatMessage({ id: "scales.pressureComponent.diastolicPressure.infoText", defaultMessage: "Inserire valori medi tra quelli misurati correttamente di recente a domicilio. Il rischio viene valutato attraverso la Pressione Arteriosa Sistolica prima del trattamento." }),
                    hintText: intl.formatMessage({ id: "scales.pressureComponent.diastolicPressure.hintText", defaultMessage: "Valori medi tra quelli misurati di recente dal medico" }),
                }
                ,
            }
            ,
            cholesterolComponent: {
                title: intl.formatMessage({ id: "scales.cholesterolComponent.title", defaultMessage: "Dati Colesterolo" }),
                totalCholesterol: {
                    label: intl.formatMessage({ id: "scales.cholesterolComponent.totalCholesterol.label", defaultMessage: "Colesterolo totale" }),
                    errorText: intl.formatMessage({ id: "scales.cholesterolComponent.totalCholesterol.errorText", defaultMessage: "Il valore deve essere intero e compreso tra 116 e 348" }),
                    hintText: intl.formatMessage({ id: "scales.cholesterolComponent.totalCholesterol.hintText", defaultMessage: "Il rischio viene valutato attraverso il Colesterolo Totale prima del trattamento." }),
                }
                ,
                hdlCholesterol: {
                    label: intl.formatMessage({ id: "scales.cholesterolComponent.hdlCholesterol.label", defaultMessage: "Colesterolo HDL" }),
                    errorText: intl.formatMessage({ id: "scales.cholesterolComponent.hdlCholesterol.errorText", defaultMessage: "Il valore deve essere intero e compreso tra 27 e 97" }),
                }
                ,
                triglycerides: {
                    label: intl.formatMessage({ id: "scales.cholesterolComponent.triglycerides.label", defaultMessage: "Trigliceridi" }),
                    errorText: intl.formatMessage({ id: "scales.cholesterolComponent.triglycerides.errorText", defaultMessage: "Il valore deve essere intero e compreso tra 7 e 13975" }),
                }
                ,
            }
            ,
            cardiovascularEventsComponent: {
                title: intl.formatMessage({ id: "scales.cardiovascularEventsComponent.title", defaultMessage: "Eventi Cardiovasculari" }),
                cardiovascularEvent: {
                    label: intl.formatMessage({ id: "scales.cardiovascularEventsComponent.cardiovascularEvent.label", defaultMessage: "Hai mai avuto un evento cardiovascolare?" }),
                    moreCardiovascularEvent: intl.formatMessage({ id: "scales.cardiovascularEventsComponent.cardiovascularEvent.moreCardiovascularEvent", defaultMessage: "Hai mai avuto più di 2 eventi cardiovascolari in 2 anni consecutivi?" }),
                    infoText: intl.formatMessage({ id: "scales.cardiovascularEventsComponent.cardiovascularEvent.infoText", defaultMessage: "Esempio infarto, ictus o equivalenti. Le malattie cardiovascolari sono un gruppo di patologie cui fanno parte le malattie ischemiche del cuore, come l'infarto acuto del miocardio e l'angina pectoris, e le malattie cerebrovascolari, come l'ictus ischemico e l’ictus emorragico. Rappresentano malattia cardiovascolare anche l’arteriopatia obliterante degli arti inferiori e l’ateromasia carotidea." }),
                }
                ,
                arteriopathy: {
                    label: intl.formatMessage({ id: "scales.cardiovascularEventsComponent.arteriopathy.label", defaultMessage: "Hai eseguito indagini strumentali che dimostrino danni alle arterie?" }),
                    infoText: intl.formatMessage({ id: "scales.cardiovascularEventsComponent.arteriopathy.infoText", defaultMessage: "Diagnosi pregressa o referto strumentale, per esempio: diagnosi di placche arterioscleroticheo, danni alle arterie (carotidi, arterie gambe, coronarie, altre)." }),
                }
                ,
            }
            ,
            diabetComponent: {
                title: intl.formatMessage({ id: "scales.diabetComponent.title", defaultMessage: "Dati Diabete" }),
                diabetes: {
                    label: intl.formatMessage({ id: "scales.diabetComponent.diabetes.label", defaultMessage: "Hai il diabete?" }),
                    diabetesSelect: {
                        label: intl.formatMessage({ id: "scales.diabetComponent.diabetes.diabetesSelect.label", defaultMessage: "Tipo di diabete" }),
                        diabete1: intl.formatMessage({ id: "scales.diabetComponent.diabetes.diabetesSelect.diabete1", defaultMessage: "Diabete mellito tipo 1" }),
                        diabete2: intl.formatMessage({ id: "scales.diabetComponent.diabetes.diabetesSelect.diabete2", defaultMessage: "Diabete mellito tipo 2" }),
                    }
                    ,
                    diabetesAge: {
                        label: intl.formatMessage({ id: "scales.diabetComponent.diabetes.diabetesAge.label", defaultMessage: "Età diagnosi" }),
                        errorText: intl.formatMessage({ id: "scales.diabetComponent.diabetes.diabetesAge.errorText", defaultMessage: "Il valore deve essere compreso tra 0 e 89" }),
                    }
                    ,
                    diabetesDiseases: {
                        label: intl.formatMessage({ id: "scales.diabetComponent.diabetes.diabetesDiseases.label", defaultMessage: "Hai tutte e tre queste patologie: microalbuminuria, retinopatia, neuropatia?" }),
                        infoText: intl.formatMessage({ id: "scales.diabetComponent.diabetes.diabetesDiseases.infoText", defaultMessage: "Sono patologie che compaiono dopo molti anni di diabete mellito e che possono essere state diagnosticate in precedenza da un medico" }),
                    }
                    ,
                }
                ,
            }
            ,
            urineComponent: {
                title: intl.formatMessage({ id: "scales.urineComponent.title", defaultMessage: "Dati Urine" }),
                albuminuria: {
                    label: intl.formatMessage({ id: "scales.urineComponent.albuminuria.label", defaultMessage: "Hai albumina nelle urine (albuminuria)?" }),
                    acr: {
                        label: intl.formatMessage({ id: "scales.urineComponent.albuminuria.acr.label", defaultMessage: "Inserire valore" }),
                        errorText: intl.formatMessage({ id: "scales.urineComponent.albuminuria.acr.errorText", defaultMessage: "Il valore deve essere intero e maggiore o uguale a 0" }),
                        infoText: intl.formatMessage({ id: "scales.urineComponent.albuminuria.acr.infoText", defaultMessage: "Inserire come come rapporto ACR o come mg nelle 24 ore." }),
                    }
                    ,
                }
                ,
            }
            ,
            TakingDrugsComponent: {
                title: intl.formatMessage({ id: "scales.TakingDrugsComponent.title", defaultMessage: "Assunzione Farmaci" }),
                cholesterolMedications: {
                    label: intl.formatMessage({ id: "scales.TakingDrugsComponent.cholesterolMedications.label", defaultMessage: "Assumi farmaci per ridurre il colesterolo?" }),
                }
                ,
                hypertensionMedications: {
                    label: intl.formatMessage({ id: "scales.TakingDrugsComponent.hypertensionMedications.label", defaultMessage: "Assumi farmaci per ridurre la pressione arteriosa?" }),
                }
                ,
            }
            ,
            glycatedComponent: {
                glycated: {
                    label: intl.formatMessage({ id: "scales.glycatedComponent.glycated.label", defaultMessage: "Emoglobina glicata" }),
                    errorText: intl.formatMessage({ id: "scales.glycatedComponent.glycated.errorText", defaultMessage: "Il valore deve essere compreso tra 5.9 e 14.2 se in %, o tra 41 e 132 se in mmol/mol" }),
                    infoText: intl.formatMessage({ id: "scales.glycatedComponent.glycated.infoText", defaultMessage: "L'emoglobina glicata o glicosilata o HbA1c costituisce uno dei criteri diagnostici per la diagnosi iniziale di diabete mellito (HbA1c > 6.5% o 48 mmol/mol) ed è un parametro utilizzato per valutare l’efficacia della terapia. I livelli di emoglobina glicata correlano con la concentrazione media di glucosio ematico durante i 2-3 mesi precedenti." }),
                }
                ,
            }
            ,
            urineAnalyzerComponent: {
                title: intl.formatMessage({ id: "scales.urineAnalyzerComponent.title", defaultMessage: "Analizzatore delle urine" }),
                errorText: intl.formatMessage({ id: "scales.urineAnalyzerComponent.errorText", defaultMessage: "Il campo è obbligatorio!" }),
                urineAnalyzerSwitch: {
                    label: intl.formatMessage({ id: "scales.urineAnalyzerComponent.urineAnalyzerSwitch.label", defaultMessage: "Hai fatto il test dell'analizzatore delle urine?" }),
                }
                ,
                urineAnalyzerTitle: {
                    label: intl.formatMessage({ id: "scales.urineAnalyzerComponent.urineAnalyzerTitle.label", defaultMessage: "Inserisci i dati del test:" }),
                }
                ,
                asc: {
                    label: intl.formatMessage({ id: "scales.urineAnalyzerComponent.asc.label", defaultMessage: "ASC" }),
                }
                ,
                leu: {
                    label: intl.formatMessage({ id: "scales.urineAnalyzerComponent.leu.label", defaultMessage: "LEU" }),
                }
                ,
                glu: {
                    label: intl.formatMessage({ id: "scales.urineAnalyzerComponent.glu.label", defaultMessage: "GLU" }),
                }
                ,
                alb: {
                    label: intl.formatMessage({ id: "scales.urineAnalyzerComponent.alb.label", defaultMessage: "ALB" }),
                }
                ,
                bil: {
                    label: intl.formatMessage({ id: "scales.urineAnalyzerComponent.bil.label", defaultMessage: "BIL" }),
                }
                ,
                ph: {
                    label: intl.formatMessage({ id: "scales.urineAnalyzerComponent.ph.label", defaultMessage: "PH" }),
                }
                ,
                sg: {
                    label: intl.formatMessage({ id: "scales.urineAnalyzerComponent.sg.label", defaultMessage: "SG" }),
                }
                ,
                ket: {
                    label: intl.formatMessage({ id: "scales.urineAnalyzerComponent.ket.label", defaultMessage: "KET" }),
                }
                ,
                pro: {
                    label: intl.formatMessage({ id: "scales.urineAnalyzerComponent.pro.label", defaultMessage: "PRO" }),
                }
                ,
                nit: {
                    label: intl.formatMessage({ id: "scales.urineAnalyzerComponent.nit.label", defaultMessage: "NIT" }),
                }
                ,
                uro: {
                    label: intl.formatMessage({ id: "scales.urineAnalyzerComponent.uro.label", defaultMessage: "URO" }),
                }
                ,
                cre: {
                    label: intl.formatMessage({ id: "scales.urineAnalyzerComponent.cre.label", defaultMessage: "CRE" }),
                }
                ,
                blo: {
                    label: intl.formatMessage({ id: "scales.urineAnalyzerComponent.blo.label", defaultMessage: "BLO" }),
                }
                ,
                ca: {
                    label: intl.formatMessage({ id: "scales.urineAnalyzerComponent.ca.label", defaultMessage: "CA" }),
                }

            }
        }
        ,
        drawer: {
            drawer_title: intl.formatMessage({ id: "drawer.drawer_title", defaultMessage: "Humtelemed" }),
            drawer_subtitle: intl.formatMessage({ id: "drawer.drawer_subtitle", defaultMessage: "Benvenuto" }),
            disconnectMenuItem: intl.formatMessage({ id: "drawer.disconnectMenuItem", defaultMessage: "Disconnetti" }),
            profileMenuItem: intl.formatMessage({ id: "drawer.profileMenuItem", defaultMessage: "Profilo" }),
            contactMenuItem: intl.formatMessage({ id: "drawer.contactMenuItem", defaultMessage: "Contatti" }),
            languageMenuItem: intl.formatMessage({ id: "drawer.languageMenuItem", defaultMessage: "Lingua" }),
        }
        ,
        shared: {
            name: intl.formatMessage({ id: "shared.name", defaultMessage: "Nome" }),
            email: intl.formatMessage({ id: "shared.email", defaultMessage: "Email" }),
            message: intl.formatMessage({ id: "shared.message", defaultMessage: "Messaggio" }),
            password: intl.formatMessage({ id: "shared.password", defaultMessage: "Password" }),
            login: intl.formatMessage({ id: "shared.login", defaultMessage: "Accedi" }),
            close: intl.formatMessage({ id: "shared.close", defaultMessage: "Chiudi" }),
            exit: intl.formatMessage({ id: "shared.exit", defaultMessage: "Esci" }),
            send: intl.formatMessage({ id: "shared.send", defaultMessage: "Invia" }),
            mapPositionButton: intl.formatMessage({ id: "shared.mapPositionButton", defaultMessage: "Posizione attuale" }),
            positionDenied: intl.formatMessage({ id: "shared.positionDenied", defaultMessage: "Negando l'accesso alla posizione, alcune funzionalità potrebbero essere limitate. TI consigliamo di modificare l'impostazione nelle preferenze del tuo browser" }),
            back: intl.formatMessage({ id: "shared.back", defaultMessage: "Torna indietro" }),
        }
        ,
        dialogs: {
            report: {
                download: intl.formatMessage({ id: "dialogs.report.download", defaultMessage: "Scarica report PDF" }),
                privateKeyPhrase: intl.formatMessage({ id: "dialogs.report.privateKeyPhrase", defaultMessage: "La seguente Chiave è utilizzata per permettere il download del pdf:" }),
                labelInputRepeatKey: intl.formatMessage({ id: "dialogs.report.labelInputRepeatKey", defaultMessage: "Ripeti la Chiave Privata per poter effettuare il download del pdf" }),
            }
            ,
            contact: {
                contactDialogTitle: intl.formatMessage({ id: "dialogs.contact.contactDialogTitle", defaultMessage: "Vuoi aderire al nostro servizio? Unisciti a noi!" }),
                contactDialogCheckMessage: intl.formatMessage({ id: "dialogs.contact.contactDialogCheckMessage", defaultMessage: "Ho visionato l'informativa della privacy e acconsento al trattamento dei dati." }),
                contactDialogSendButton: intl.formatMessage({ id: "dialogs.contact.contactDialogSendButton", defaultMessage: "Invia messaggio" }),
            }
            ,
            login: {
                loginDialogError: intl.formatMessage({ id: "dialogs.login.loginDialogError", defaultMessage: "Email o password non sono riconosciuti" }),
                loginDialogForgotPassword: intl.formatMessage({ id: "dialogs.login.loginDialogForgotPassword", defaultMessage: "Password dimenticata?" }),
                notRegisterContact: intl.formatMessage({ id: "dialogs.login.notRegisterContact", defaultMessage: "Unisciti a noi!" }),
                notRegisterYetMessage: intl.formatMessage({ id: "dialogs.login.notRegisterYetMessage", defaultMessage: "Vuoi aderire al nostro servizio?" }),
                loginDialogCloseForgotPassword: intl.formatMessage({ id: "dialogs.login.loginDialogCloseForgotPassword", defaultMessage: "Ho ricordato la mia password, torna indietro" }),
                loginDialogSendForgotPasswordRequest: intl.formatMessage({ id: "dialogs.login.loginDialogSendForgotPasswordRequest", defaultMessage: "Invia la richiesta" }),
                loginDialogTitleReset: intl.formatMessage({ id: "dialogs.login.loginDialogTitleReset", defaultMessage: "Password dimenticata" }),
                loginDialogTitle: intl.formatMessage({ id: "dialogs.login.loginDialogTitle", defaultMessage: "Accedi" }),
                genericErrorResetPassword: intl.formatMessage({ id: "dialogs.login.genericErrorResetPassword", defaultMessage: "Si é verificato un errore durante la richiesta. Riprova piu tardi oppure contattaci se il problema persiste." }),
                wrongPasswordOrEmail: intl.formatMessage({ id: "dialogs.login.wrongPasswordOrEmail", defaultMessage: "E-mail o password non validi. Assicurati di aver digitato correttamente i dati." }),
                genericErrorLogin: intl.formatMessage({ id: "dialogs.login.genericErrorLogin", defaultMessage: "Si è verificato un errore durante il login. Riprova piu tardi oppure contattaci se il problema persiste." }),
                userDoesNotExist: intl.formatMessage({ id: "dialogs.login.userDoesNotExist", defaultMessage: "Non è stato trovato nessun utente con l'indirizzo fornito" }),
            }
            ,
            message: {
                confirmMessageHeader: intl.formatMessage({ id: "dialogs.message.confirmMessageHeader", defaultMessage: "Messaggio inviato correttamente!" }),
                confirmMessageSent: intl.formatMessage({ id: "dialogs.message.confirmMessageSent", defaultMessage: "Messaggio inviato correttamente! Riceverai una risposta nella email da te indicata non appena i nostri specialisti avranno analizzato il tuo quesito." }),
            }
            ,
            changePassword: {
                dialogChangePassword: intl.formatMessage({ id: "dialogs.changePassword.dialogChangePassword", defaultMessage: "Cambia la tua password" }),
            }
            ,
            expireSession: {
                sessionExpireTitle: intl.formatMessage({ id: "dialogs.expireSession.sessionExpireTitle", defaultMessage: "Sessione scaduta" }),
                sessionExpireText: intl.formatMessage({ id: "dialogs.expireSession.sessionExpireText", defaultMessage: "La tua sessione è scaduta. Per riaccedere, clicca sul pulsante sotto per ricaricare il sito e riesegui il login." }),
            }
            ,
            updatePharmacy: {
                title: intl.formatMessage({ id: "dialogs.updatePharmacy.title", defaultMessage: "Modifica Farmacia" }),
                name: intl.formatMessage({ id: "dialogs.updatePharmacy.name", defaultMessage: "Nome" }),
                email: intl.formatMessage({ id: "dialogs.updatePharmacy.email", defaultMessage: "Email" }),
                city: intl.formatMessage({ id: "dialogs.updatePharmacy.city", defaultMessage: "Città" }),
                address: intl.formatMessage({ id: "dialogs.updatePharmacy.address", defaultMessage: "Indirizzo" }),
                postCode: intl.formatMessage({ id: "dialogs.updatePharmacy.postCode", defaultMessage: "CAP" }),
                vatNumber: intl.formatMessage({ id: "dialogs.updatePharmacy.vatNumber", defaultMessage: "Partita Iva" }),
                phoneNumber: intl.formatMessage({ id: "dialogs.updatePharmacy.phoneNumber", defaultMessage: "Telefono" }),
            }
            ,
            error: {
                title: intl.formatMessage({ id: "dialogs.error.title", defaultMessage: "Errore" }),
                errorTest: intl.formatMessage({ id: "dialogs.error.errorTest", defaultMessage: "Ci scusiamo, si è verificato un errore! Riprovate, se l'errore persiste contattate il team di Humtelemed" }),
            }
            ,
            confirmDialog: {
                addTitle: intl.formatMessage({ id: "dialogs.confirmDialog.addTitle", defaultMessage: "Conferma nuovo inserimento" }),
                addContent: intl.formatMessage({ id: "dialogs.confirmDialog.addContent", defaultMessage: "Se confermi sarà effettuato l'inserimento dei nuovi dati!" }),
                modifyTitle: intl.formatMessage({ id: "dialogs.confirmDialog.modifyTitle", defaultMessage: "Conferma modifica" }),
                modifyContent: intl.formatMessage({ id: "dialogs.confirmDialog.modifyContent", defaultMessage: "Se confermi, le modifiche verranno apportate!" }),
                deleteTitle: intl.formatMessage({ id: "dialogs.confirmDialog.deleteTitle", defaultMessage: "Conferma cancellazione" }),
                deleteContent: intl.formatMessage({ id: "dialogs.confirmDialog.deleteContent", defaultMessage: "Se confermi, la cancellazione sarà eseguita!" }),
                confirmButtom: intl.formatMessage({ id: "dialogs.confirmDialog.confirmButtom", defaultMessage: "Conferma" }),
                rejectionButton: intl.formatMessage({ id: "dialogs.confirmDialog.rejectionButton", defaultMessage: "Annulla" }),
            }
            ,
        }
        ,
        countries: {
            algeria: intl.formatMessage({ id: "countries.algeria", defaultMessage: "Algeria" }),
            andorra: intl.formatMessage({ id: "countries.andorra", defaultMessage: "Andorra" }),
            armenia: intl.formatMessage({ id: "countries.armenia", defaultMessage: "Armenia" }),
            austria: intl.formatMessage({ id: "countries.austria", defaultMessage: "Austria" }),
            bosnia_herzegovina: intl.formatMessage({ id: "countries.bosnia_herzegovina", defaultMessage: "Bosnia Erzegovina" }),
            belgium: intl.formatMessage({ id: "countries.belgium", defaultMessage: "Belgio" }),
            bulgaria: intl.formatMessage({ id: "countries.bulgaria", defaultMessage: "Bulgaria" }),
            croatia: intl.formatMessage({ id: "countries.croatia", defaultMessage: "Croazia" }),
            cyprus: intl.formatMessage({ id: "countries.cyprus", defaultMessage: "Cipro" }),
            denmark: intl.formatMessage({ id: "countries.denmark", defaultMessage: "Danimarca" }),
            egypt: intl.formatMessage({ id: "countries.egypt", defaultMessage: "Egitto" }),
            estonia: intl.formatMessage({ id: "countries.estonia", defaultMessage: "Estonia" }),
            finland: intl.formatMessage({ id: "countries.finland", defaultMessage: "Finlandia" }),
            france: intl.formatMessage({ id: "countries.france", defaultMessage: "Francia" }),
            georgia: intl.formatMessage({ id: "countries.georgia", defaultMessage: "Georgia" }),
            germany: intl.formatMessage({ id: "countries.germany", defaultMessage: "Germania" }),
            greece: intl.formatMessage({ id: "countries.greece", defaultMessage: "Grecia" }),
            iceland: intl.formatMessage({ id: "countries.iceland", defaultMessage: "Islanda" }),
            israel: intl.formatMessage({ id: "countries.israel", defaultMessage: "Israele" }),
            ireland: intl.formatMessage({ id: "countries.ireland", defaultMessage: "Irlanda" }),
            northern_ireland: intl.formatMessage({ id: "countries.northern_ireland", defaultMessage: "Irlanda del Nord" }),
            italy: intl.formatMessage({ id: "countries.italy", defaultMessage: "Italia" }),
            kazakhstan: intl.formatMessage({ id: "countries.kazakhstan", defaultMessage: "Kazakistan" }),
            kyrgyzstan: intl.formatMessage({ id: "countries.kyrgyzstan", defaultMessage: "Kirghizistan" }),
            latvia: intl.formatMessage({ id: "countries.latvia", defaultMessage: "Lettonia" }),
            lebanon: intl.formatMessage({ id: "countries.lebanon", defaultMessage: "Libano" }),
            libya: intl.formatMessage({ id: "countries.libya", defaultMessage: "Libia" }),
            lithuania: intl.formatMessage({ id: "countries.lithuania", defaultMessage: "Lituania" }),
            luxembourg: intl.formatMessage({ id: "countries.luxembourg", defaultMessage: "Lussemburgo" }),
            macedonia: intl.formatMessage({ id: "countries.macedonia", defaultMessage: "Macedonia" }),
            malta: intl.formatMessage({ id: "countries.malta", defaultMessage: "Malta" }),
            marocco: intl.formatMessage({ id: "countries.marocco", defaultMessage: "Marocco" }),
            moldova: intl.formatMessage({ id: "countries.moldova", defaultMessage: "Moldavia" }),
            monaco: intl.formatMessage({ id: "countries.monaco", defaultMessage: "Monaco" }),
            montenegro: intl.formatMessage({ id: "countries.montenegro", defaultMessage: "Montenegro" }),
            norway: intl.formatMessage({ id: "countries.norway", defaultMessage: "Norvegia" }),
            netherlands: intl.formatMessage({ id: "countries.netherlands", defaultMessage: "Paesi Bassi" }),
            poland: intl.formatMessage({ id: "countries.poland", defaultMessage: "Polonia" }),
            portugal: intl.formatMessage({ id: "countries.portugal", defaultMessage: "Portogallo" }),
            united_kingdom: intl.formatMessage({ id: "countries.united_kingdom", defaultMessage: "Regno Unito" }),
            romania: intl.formatMessage({ id: "countries.romania", defaultMessage: "Romania" }),
            czech_republic: intl.formatMessage({ id: "countries.czech_republic", defaultMessage: "Repubblica Ceca" }),
            russia: intl.formatMessage({ id: "countries.russia", defaultMessage: "Russia" }),
            san_marino: intl.formatMessage({ id: "countries.san_marino", defaultMessage: "San Marino" }),
            serbia: intl.formatMessage({ id: "countries.serbia", defaultMessage: "Serbia" }),
            syria: intl.formatMessage({ id: "countries.syria", defaultMessage: "Siria" }),
            slovakia: intl.formatMessage({ id: "countries.slovakia", defaultMessage: "Slovacchia" }),
            slovenia: intl.formatMessage({ id: "countries.slovenia", defaultMessage: "Slovenia" }),
            spain: intl.formatMessage({ id: "countries.spain", defaultMessage: "Spagna" }),
            sweden: intl.formatMessage({ id: "countries.sweden", defaultMessage: "Svezia" }),
            swiss: intl.formatMessage({ id: "countries.swiss", defaultMessage: "Svizzera" }),
            tunisia: intl.formatMessage({ id: "countries.tunisia", defaultMessage: "Tunisia" }),
            turkey: intl.formatMessage({ id: "countries.turkey", defaultMessage: "Turchia" }),
            ukraine: intl.formatMessage({ id: "countries.ukraine", defaultMessage: "Ucraina" }),
            hungary: intl.formatMessage({ id: "countries.hungary", defaultMessage: "Ungheria" }),
            uzbekistan: intl.formatMessage({ id: "countries.uzbekistan", defaultMessage: "Uzbekistan" }),
        }
        ,
        cookie: {
            CookieTitle: intl.formatMessage({ id: "cookie.CookieTitle", defaultMessage: "Impostazioni dei cookie" }),
            CookieIntro: intl.formatMessage({ id: "cookie.CookieIntro", defaultMessage: "Questo sito utilizza i cookie per analizzare il traffico web per fini di ricerca, cliccando sul pulsante 'accetta' o continuando la navigazione acconsenti al loro utilizzo. Ulteriori informazioni sono disponibili nella nostra" }),
            CookieAcceptText: intl.formatMessage({ id: "cookie.CookieAcceptText", defaultMessage: "Ok" }),
            CookieDeclineText: intl.formatMessage({ id: "cookie.CookieDeclineText", defaultMessage: "Accettare solo i cookie tecnici necessari" }),
            requiredField: intl.formatMessage({ id: "cookie.requiredField", defaultMessage: "Il campo non può essere vuoto" }),
        }
        ,
        pdf: {
            scales: {
                lopreHolder: {
                    title: intl.formatMessage({ id: "pdf.scales.lopreHolder.title", defaultMessage: "VALUTAZIONE DEL RISCHIO CARDIOVASCOLARE GLOBALE" }),
                    subtitle: intl.formatMessage({ id: "pdf.scales.lopreHolder.subtitle", defaultMessage: "Semplificato per le farmacie" }),
                    anthropometric: {
                        title: intl.formatMessage({ id: "pdf.scales.lopreHolder.anthropometric.title", defaultMessage: "Dati anagrafici ed antropometrici" }),
                        age: intl.formatMessage({ id: "pdf.scales.lopreHolder.anthropometric.age", defaultMessage: "Età" }),
                        sex: intl.formatMessage({ id: "pdf.scales.lopreHolder.anthropometric.sex", defaultMessage: "Sesso" }),
                        height: intl.formatMessage({ id: "pdf.scales.lopreHolder.anthropometric.height", defaultMessage: "Altezza" }),
                        weight: intl.formatMessage({ id: "pdf.scales.lopreHolder.anthropometric.weight", defaultMessage: "Peso" }),
                        bmi: intl.formatMessage({ id: "pdf.scales.lopreHolder.anthropometric.bmi", defaultMessage: "Indice di massa corporea" }),
                        country: intl.formatMessage({ id: "pdf.scales.lopreHolder.anthropometric.country", defaultMessage: "Dati anagrafici ed antropometrici" }),
                    }
                    ,
                    clinicals_data: {
                        title: intl.formatMessage({ id: "pdf.scales.lopreHolder.clinicals_data.title", defaultMessage: "Dati clinici e di laboratorio" }),
                        totalCholesterol: intl.formatMessage({ id: "pdf.scales.lopreHolder.clinicals_data.totalCholesterol", defaultMessage: "Colesterolo totale" }),
                        hdlCholesterol: intl.formatMessage({ id: "pdf.scales.lopreHolder.clinicals_data.hdlCholesterol", defaultMessage: "Colesterolo HDL" }),
                        nonHdlCholesterol: intl.formatMessage({ id: "pdf.scales.lopreHolder.clinicals_data.nonHdlCholesterol", defaultMessage: "Colesterolo non-HDL" }),
                        creatinine: intl.formatMessage({ id: "pdf.scales.lopreHolder.clinicals_data.creatinine", defaultMessage: "Creatinina" }),
                        egfr: intl.formatMessage({ id: "pdf.scales.lopreHolder.clinicals_data.egfr", defaultMessage: "eGFR" }),
                        systolicPressure: intl.formatMessage({ id: "pdf.scales.lopreHolder.clinicals_data.systolicPressure", defaultMessage: "Pressione sistolica" }),
                        diastolicPressure: intl.formatMessage({ id: "pdf.scales.lopreHolder.clinicals_data.diastolicPressure", defaultMessage: "Pressione diastolica" }),
                        diabetes2: intl.formatMessage({ id: "pdf.scales.lopreHolder.clinicals_data.diabetes2", defaultMessage: "Diabete di tipo 2" }),
                        cardiovascularEvent: intl.formatMessage({ id: "pdf.scales.lopreHolder.clinicals_data.cardiovascularEvent", defaultMessage: "Evento cardiovascolare precedente" }),
                        moreCardiovascularEvent: intl.formatMessage({ id: "pdf.scales.lopreHolder.clinicals_data.moreCardiovascularEvent", defaultMessage: "Più di due eventi cardiovascolari in 2 anni consecutivi" }),
                    }
                    ,
                    result: {
                        title: intl.formatMessage({ id: "pdf.scales.lopreHolder.result.title", defaultMessage: "Esito" }),
                        score2: intl.formatMessage({ id: "pdf.scales.lopreHolder.result.score2", defaultMessage: "Score2" }),
                        estimatedRisk: intl.formatMessage({ id: "pdf.scales.lopreHolder.result.estimatedRisk", defaultMessage: "Rischio attuale stimato" }),
                        eventProbability: intl.formatMessage({ id: "pdf.scales.lopreHolder.result.eventProbability", defaultMessage: "Sulla base dei dati da te inseriti, la tua probabilità stimata di avere un evento cardiovascolare nei prossimi 10 anni è" }),
                    }
                    ,
                    recommendations: {
                        title: intl.formatMessage({ id: "pdf.scales.lopreHolder.recommendations.title", defaultMessage: "Raccomandazioni da seguire dopo consulto medico" }),
                        cholesterol1: intl.formatMessage({ id: "pdf.scales.lopreHolder.recommendations.cholesterol1", defaultMessage: "Portare il colesterolo" }),
                        cholesterol2: intl.formatMessage({ id: "pdf.scales.lopreHolder.recommendations.cholesterol2", defaultMessage: "a valori inferiori a" }),
                        cholesterolLDL: intl.formatMessage({ id: "pdf.scales.lopreHolder.recommendations.cholesterolLDL", defaultMessage: "Il valore rappresenta la soglia sotto la quale bisogna far scendere il colesterolo LDL per ottenere massima protezione da eventi cardiovascolari. Il valore tiene conto anche dei valori basali di colesterolo LDL e della regola di far scendere anche sotto al 50% tali valori quando il rischio cardiovascolare sia da elevato in su." }),
                        cholesterolNotHDL: intl.formatMessage({ id: "pdf.scales.lopreHolder.recommendations.cholesterolNotHDL", defaultMessage: "La colesterolemia non-HDL rappresenta complessivamente tutto il colesterolo aterogeno circolante ed è quella usata per valutare il rischio nelle carte SCORE2. I valori a cui portarlo sono stimati essere di 30 mg/dL superiori a quelli a cui si deve far scendere il colesterolo LDL, questo vale soprattutto per chi ha anche una trigliceridemia da 200 mg/dL in su." }),
                        bloodPressure: intl.formatMessage({ id: "pdf.scales.lopreHolder.recommendations.bloodPressure", defaultMessage: "Mantenere la pressione arteriosa a valori inferiori a 130/80 mmHg nella maggior parte dei casi." }),
                    }
                    ,
                    helpers: {
                        yes: intl.formatMessage({ id: "pdf.scales.lopreHolder.helpers.yes", defaultMessage: "Sì" }),
                        no: intl.formatMessage({ id: "pdf.scales.lopreHolder.helpers.no", defaultMessage: "No" }),
                        years: intl.formatMessage({ id: "pdf.scales.lopreHolder.helpers.years", defaultMessage: "anni" }),
                        wildchartInfo: intl.formatMessage({ id: "pdf.scales.lopreHolder.helpers.wildchartInfo", defaultMessage: "i valori contrassegnati sono stati stimati sulla base degli altri dati inseriti." }),
                    }
                    ,
                    info: {
                        usage: intl.formatMessage({ id: "pdf.scales.lopreHolder.info.usage", defaultMessage: "Esplicitazione delle motivazioni del risultato sulla valutazione del rischio. Per uso soprattutto professionale (Medico)" }),
                        metrics: intl.formatMessage({ id: "pdf.scales.lopreHolder.info.metrics", defaultMessage: "Il rischio è stato stimato sulla base delle seguenti linee guida: ESC Guidelines on cardiovascular disease prevention in clinical practice. Eur Heart J. 42, 3227–3337, 2021; SCORE2 risk prediction algorithms: new models to estimate 10-year risk of cardiovascular disease in Europe. Eur Heart J. 42, 2439–2454, 2021; SCORE2-OP risk prediction algorithms, Eur Heart J. 42, 2455–2467; ESC/EAS Guidelines for the management of dyslipidaemias Eur Heart J 41, 111-188, 2020; ESC/ESH Guidelines for the management of arterial hypertension Eur Heart J 39, 3021-3104, 2018." }),
                    }
                    ,
                }
                ,
                scaleFree: {
                    title: intl.formatMessage({ id: "pdf.scales.scaleFree.title", defaultMessage: "VALUTAZIONE DEL RISCHIO CARDIOVASCOLARE GLOBALE" }),
                    anthropometric: {
                        title: intl.formatMessage({ id: "pdf.scales.scaleFree.anthropometric.title", defaultMessage: "Dati anagrafici ed antropometrici" }),
                        age: intl.formatMessage({ id: "pdf.scales.scaleFree.anthropometric.age", defaultMessage: "Età" }),
                        sex: intl.formatMessage({ id: "pdf.scales.scaleFree.anthropometric.sex", defaultMessage: "Sesso" }),
                        height: intl.formatMessage({ id: "pdf.scales.scaleFree.anthropometric.height", defaultMessage: "Altezza" }),
                        weight: intl.formatMessage({ id: "pdf.scales.scaleFree.anthropometric.weight", defaultMessage: "Peso" }),
                        bmi: intl.formatMessage({ id: "pdf.scales.scaleFree.anthropometric.bmi", defaultMessage: "Indice di massa corporea" }),
                        country: intl.formatMessage({ id: "pdf.scales.scaleFree.anthropometric.country", defaultMessage: "Dati anagrafici ed antropometrici" }),
                    }
                    ,
                    clinicals_data: {
                        title: intl.formatMessage({ id: "pdf.scales.scaleFree.clinicals_data.title", defaultMessage: "Dati clinici e di laboratorio" }),
                        totalCholesterol: intl.formatMessage({ id: "pdf.scales.scaleFree.clinicals_data.totalCholesterol", defaultMessage: "Colesterolo totale" }),
                        hdlCholesterol: intl.formatMessage({ id: "pdf.scales.scaleFree.clinicals_data.hdlCholesterol", defaultMessage: "Colesterolo HDL" }),
                        ldlCholesterol: intl.formatMessage({ id: "pdf.scales.scaleFree.clinicals_data.ldlCholesterol", defaultMessage: "Colesterolo LDL" }),
                        nonHdlCholesterol: intl.formatMessage({ id: "pdf.scales.scaleFree.clinicals_data.nonHdlCholesterol", defaultMessage: "Colesterolo non-HDL" }),
                        triglycerides: intl.formatMessage({ id: "pdf.scales.scaleFree.clinicals_data.triglycerides", defaultMessage: "Trigliceridi" }),
                        creatinine: intl.formatMessage({ id: "pdf.scales.scaleFree.clinicals_data.creatinine", defaultMessage: "Creatinina" }),
                        egfr: intl.formatMessage({ id: "pdf.scales.scaleFree.clinicals_data.egfr", defaultMessage: "eGFR" }),
                        systolicPressure: intl.formatMessage({ id: "pdf.scales.scaleFree.clinicals_data.systolicPressure", defaultMessage: "Pressione sistolica" }),
                        diastolicPressure: intl.formatMessage({ id: "pdf.scales.scaleFree.clinicals_data.diastolicPressure", defaultMessage: "Pressione diastolica" }),
                        diabetes1: intl.formatMessage({ id: "pdf.scales.scaleFree.clinicals_data.diabetes1", defaultMessage: "Diabete di tipo 1" }),
                        diabetes2: intl.formatMessage({ id: "pdf.scales.scaleFree.clinicals_data.diabetes2", defaultMessage: "Diabete di tipo 2" }),
                        diabetesYes: intl.formatMessage({ id: "pdf.scales.scaleFree.clinicals_data.diabetesYes", defaultMessage: "Sì, diagnosticato a" }),
                        familyHychol: intl.formatMessage({ id: "pdf.scales.scaleFree.clinicals_data.familyHychol", defaultMessage: "Ipercolesterolemia familiare genetica" }),
                        cardiovascularEvent: intl.formatMessage({ id: "pdf.scales.scaleFree.clinicals_data.cardiovascularEvent", defaultMessage: "Evento cardiovascolare precedente" }),
                        moreCardiovascularEvent: intl.formatMessage({ id: "pdf.scales.scaleFree.clinicals_data.moreCardiovascularEvent", defaultMessage: "Più di due eventi cardiovascolari in 2 anni consecutivi" }),
                    }
                    ,
                    actual_treatment: {
                        title: intl.formatMessage({ id: "pdf.scales.scaleFree.actual_treatment.title", defaultMessage: "Trattamento attuale" }),
                        hypertensionMedications: intl.formatMessage({ id: "pdf.scales.scaleFree.actual_treatment.hypertensionMedications", defaultMessage: "Assume farmaci contro l'ipertensione" }),
                        cholesterolMedications: intl.formatMessage({ id: "pdf.scales.scaleFree.actual_treatment.cholesterolMedications", defaultMessage: "Assume farmaci contro il colesterolo" }),
                    }
                    ,
                    risk_factor: {
                        title: intl.formatMessage({ id: "pdf.scales.scaleFree.risk_factor.title", defaultMessage: "Fattori di rischio" }),
                        smoke: intl.formatMessage({ id: "pdf.scales.scaleFree.risk_factor.smoke", defaultMessage: "Abitudine al fumo" }),
                        hypertension: intl.formatMessage({ id: "pdf.scales.scaleFree.risk_factor.hypertension", defaultMessage: "Ipertensione" }),
                        renalIsufficiency: intl.formatMessage({ id: "pdf.scales.scaleFree.risk_factor.renalIsufficiency", defaultMessage: "Insufficienza renale" }),
                        dislipidemia: intl.formatMessage({ id: "pdf.scales.scaleFree.risk_factor.dislipidemia", defaultMessage: "Dislipidemia" }),
                    }
                    ,
                    result: {
                        title: intl.formatMessage({ id: "pdf.scales.scaleFree.result.title", defaultMessage: "Esito" }),
                        estimatedRisk: intl.formatMessage({ id: "pdf.scales.scaleFree.result.estimatedRisk", defaultMessage: "Rischio attuale stimato" }),
                        eventProbability: intl.formatMessage({ id: "pdf.scales.scaleFree.result.eventProbability", defaultMessage: "Sulla base dei dati da te inseriti, la tua probabilità stimata di avere un evento cardiovascolare nei prossimi 10 anni è" }),
                    }
                    ,
                    recommendations: {
                        title: intl.formatMessage({ id: "pdf.scales.scaleFree.recommendations.title", defaultMessage: "Raccomandazioni da seguire dopo consulto medico" }),
                        cholesterol1: intl.formatMessage({ id: "pdf.scales.scaleFree.recommendations.cholesterol1", defaultMessage: "Portare il colesterolo" }),
                        cholesterol2: intl.formatMessage({ id: "pdf.scales.scaleFree.recommendations.cholesterol2", defaultMessage: "a valori inferiori a" }),
                        cholesterolLDL: intl.formatMessage({ id: "pdf.scales.scaleFree.recommendations.cholesterolLDL", defaultMessage: "Il valore rappresenta la soglia sotto la quale bisogna far scendere il colesterolo LDL per ottenere massima protezione da eventi cardiovascolari. Il valore tiene conto anche dei valori basali di colesterolo LDL e della regola di far scendere anche sotto al 50% tali valori quando il rischio cardiovascolare sia da elevato in su." }),
                        cholesterolNotHDL: intl.formatMessage({ id: "pdf.scales.scaleFree.recommendations.cholesterolNotHDL", defaultMessage: "La colesterolemia non-HDL rappresenta complessivamente tutto il colesterolo aterogeno circolante ed è quella usata per valutare il rischio nelle carte SCORE2. I valori a cui portarlo sono stimati essere di 30 mg/dL superiori a quelli a cui si deve far scendere il colesterolo LDL, questo vale soprattutto per chi ha anche una trigliceridemia da 200 mg/dL in su." }),
                        bloodPressure: intl.formatMessage({ id: "pdf.scales.scaleFree.recommendations.bloodPressure", defaultMessage: "Mantenere la pressione arteriosa a valori inferiori a 130/80 mmHg nella maggior parte dei casi." }),
                    }
                    ,
                    helpers: {
                        yes: intl.formatMessage({ id: "pdf.scales.scaleFree.helpers.yes", defaultMessage: "Sì" }),
                        no: intl.formatMessage({ id: "pdf.scales.scaleFree.helpers.no", defaultMessage: "No" }),
                        years: intl.formatMessage({ id: "pdf.scales.scaleFree.helpers.years", defaultMessage: "anni" }),
                        wildchartInfo: intl.formatMessage({ id: "pdf.scales.scaleFree.helpers.wildchartInfo", defaultMessage: "i valori contrassegnati sono stati stimati sulla base degli altri dati inseriti, in particolare il colesterolo LDL è calcolato con la formula di Friedewald modificata secondo Martin-Hopkins, mentre il colesterolo non-HDL rappresenta tutto il colesterolo aterogeno circolante (colesterolo totale da cui si sottrae il colesterolo HDL). La stima della velocità di filtrazione renale è stata eseguita secondo formula CKD-EPI." }),
                    }
                    ,
                    info: {
                        usage: intl.formatMessage({ id: "pdf.scales.scaleFree.info.usage", defaultMessage: "Esplicitazione delle motivazioni del risultato sulla valutazione del rischio. Per uso soprattutto professionale (Medico)" }),
                        metrics: intl.formatMessage({ id: "pdf.scales.scaleFree.info.metrics", defaultMessage: "Il rischio è stato stimato sulla base delle seguenti linee guida: ESC Guidelines on cardiovascular disease prevention in clinical practice. Eur Heart J. 42, 3227–3337, 2021; SCORE2 risk prediction algorithms: new models to estimate 10-year risk of cardiovascular disease in Europe. Eur Heart J. 42, 2439–2454, 2021; SCORE2-OP risk prediction algorithms, Eur Heart J. 42, 2455–2467; ESC/EAS Guidelines for the management of dyslipidaemias Eur Heart J 41, 111-188, 2020; ESC/ESH Guidelines for the management of arterial hypertension Eur Heart J 39, 3021-3104, 2018." }),
                    }
                    ,
                }
                ,
                scaleGlycated: {
                    title: intl.formatMessage({ id: "pdf.scales.scaleGlycated.title", defaultMessage: "VALUTAZIONE DEL RISCHIO CARDIOVASCOLARE GLOBALE" }),
                    subtitle: intl.formatMessage({ id: "pdf.scales.scaleGlycated.subtitle", defaultMessage: "Semplificato per le farmacie" }),
                    anthropometric: {
                        title: intl.formatMessage({ id: "pdf.scales.scaleGlycated.anthropometric.title", defaultMessage: "Dati anagrafici ed antropometrici" }),
                        age: intl.formatMessage({ id: "pdf.scales.scaleGlycated.anthropometric.age", defaultMessage: "Età" }),
                        sex: intl.formatMessage({ id: "pdf.scales.scaleGlycated.anthropometric.sex", defaultMessage: "Sesso" }),
                        height: intl.formatMessage({ id: "pdf.scales.scaleGlycated.anthropometric.height", defaultMessage: "Altezza" }),
                        weight: intl.formatMessage({ id: "pdf.scales.scaleGlycated.anthropometric.weight", defaultMessage: "Peso" }),
                        bmi: intl.formatMessage({ id: "pdf.scales.scaleGlycated.anthropometric.bmi", defaultMessage: "Indice di massa corporea" }),
                        country: intl.formatMessage({ id: "pdf.scales.scaleGlycated.anthropometric.country", defaultMessage: "Dati anagrafici ed antropometrici" }),
                    }
                    ,
                    clinicals_data: {
                        title: intl.formatMessage({ id: "pdf.scales.scaleGlycated.clinicals_data.title", defaultMessage: "Dati clinici e di laboratorio" }),
                        totalCholesterol: intl.formatMessage({ id: "pdf.scales.scaleGlycated.clinicals_data.totalCholesterol", defaultMessage: "Colesterolo totale" }),
                        hdlCholesterol: intl.formatMessage({ id: "pdf.scales.scaleGlycated.clinicals_data.hdlCholesterol", defaultMessage: "Colesterolo HDL" }),
                        nonHdlCholesterol: intl.formatMessage({ id: "pdf.scales.scaleGlycated.clinicals_data.nonHdlCholesterol", defaultMessage: "Colesterolo non-HDL" }),
                        creatinine: intl.formatMessage({ id: "pdf.scales.scaleGlycated.clinicals_data.creatinine", defaultMessage: "Creatinina" }),
                        egfr: intl.formatMessage({ id: "pdf.scales.scaleGlycated.clinicals_data.egfr", defaultMessage: "eGFR" }),
                        systolicPressure: intl.formatMessage({ id: "pdf.scales.scaleGlycated.clinicals_data.systolicPressure", defaultMessage: "Pressione sistolica" }),
                        diastolicPressure: intl.formatMessage({ id: "pdf.scales.scaleGlycated.clinicals_data.diastolicPressure", defaultMessage: "Pressione diastolica" }),
                        diabetes2: intl.formatMessage({ id: "pdf.scales.scaleGlycated.clinicals_data.diabetes2", defaultMessage: "Diabete di tipo 2" }),
                        glycated: intl.formatMessage({ id: "pdf.scales.scaleGlycated.clinicals_data.glycated", defaultMessage: "Emoglobina glicata" }),
                        cardiovascularEvent: intl.formatMessage({ id: "pdf.scales.scaleGlycated.clinicals_data.cardiovascularEvent", defaultMessage: "Evento cardiovascolare precedente" }),
                        moreCardiovascularEvent: intl.formatMessage({ id: "pdf.scales.scaleGlycated.clinicals_data.moreCardiovascularEvent", defaultMessage: "Più di due eventi cardiovascolari in 2 anni consecutivi" }),
                    }
                    ,
                    result: {
                        title: intl.formatMessage({ id: "pdf.scales.scaleGlycated.result.title", defaultMessage: "Esito" }),
                        score2: intl.formatMessage({ id: "pdf.scales.scaleGlycated.result.score2", defaultMessage: "Score2" }),
                        estimatedRisk: intl.formatMessage({ id: "pdf.scales.scaleGlycated.result.estimatedRisk", defaultMessage: "Rischio attuale stimato" }),
                        eventProbability: intl.formatMessage({ id: "pdf.scales.scaleGlycated.result.eventProbability", defaultMessage: "Sulla base dei dati da te inseriti, la tua probabilità stimata di avere un evento cardiovascolare nei prossimi 10 anni è" }),
                    }
                    ,
                    recommendations: {
                        title: intl.formatMessage({ id: "pdf.scales.scaleGlycated.recommendations.title", defaultMessage: "Raccomandazioni da seguire dopo consulto medico" }),
                        cholesterol1: intl.formatMessage({ id: "pdf.scales.scaleGlycated.recommendations.cholesterol1", defaultMessage: "Portare il colesterolo" }),
                        cholesterol2: intl.formatMessage({ id: "pdf.scales.scaleGlycated.recommendations.cholesterol2", defaultMessage: "a valori inferiori a" }),
                        cholesterolLDL: intl.formatMessage({ id: "pdf.scales.scaleGlycated.recommendations.cholesterolLDL", defaultMessage: "Il valore rappresenta la soglia sotto la quale bisogna far scendere il colesterolo LDL per ottenere massima protezione da eventi cardiovascolari. Il valore tiene conto anche dei valori basali di colesterolo LDL e della regola di far scendere anche sotto al 50% tali valori quando il rischio cardiovascolare sia da elevato in su." }),
                        cholesterolNotHDL: intl.formatMessage({ id: "pdf.scales.scaleGlycated.recommendations.cholesterolNotHDL", defaultMessage: "La colesterolemia non-HDL rappresenta complessivamente tutto il colesterolo aterogeno circolante ed è quella usata per valutare il rischio nelle carte SCORE2. I valori a cui portarlo sono stimati essere di 30 mg/dL superiori a quelli a cui si deve far scendere il colesterolo LDL, questo vale soprattutto per chi ha anche una trigliceridemia da 200 mg/dL in su." }),
                        bloodPressure: intl.formatMessage({ id: "pdf.scales.scaleGlycated.recommendations.bloodPressure", defaultMessage: "Mantenere la pressione arteriosa a valori inferiori a 130/80 mmHg nella maggior parte dei casi." }),
                        possibleDiagnosiOfDiabetesMellitus: intl.formatMessage({ id: "pdf.scales.scaleGlycated.recommendations.possibleDiagnosiOfDiabetesMellitus", defaultMessage: "Valore di emoglobina glicata compatibile con diagnosi di Diabete Mellito. Si consiglia di far visionare questo report al proprio medico curante." }),
                        optimalGlycaemicControl: intl.formatMessage({ id: "pdf.scales.scaleGlycated.recommendations.optimalGlycaemicControl", defaultMessage: "Ottimale controllo glicemico." }),
                        nonOptimalGlycaemicControl: intl.formatMessage({ id: "pdf.scales.scaleGlycated.recommendations.nonOptimalGlycaemicControl", defaultMessage: "Non ottimale controllo glicemico. Si consiglia di far visionare questo report al proprio medico curante o allo specialista diabetologo al CAD di riferimento." }),
                        over80GlycaemicControl: intl.formatMessage({ id: "pdf.scales.scaleGlycated.recommendations.over80GlycaemicControl", defaultMessage: "Valori di emoglobina glicata fino a 8.5% sono considerati accettabili nei grandi anziani, al contrario valori che tendono troppo al basso possono mettere a rischio il paziente: rivolgiti al tuo medico." }),
                    }
                    ,
                    helpers: {
                        yes: intl.formatMessage({ id: "pdf.scales.scaleGlycated.helpers.yes", defaultMessage: "Sì" }),
                        no: intl.formatMessage({ id: "pdf.scales.scaleGlycated.helpers.no", defaultMessage: "No" }),
                        years: intl.formatMessage({ id: "pdf.scales.scaleGlycated.helpers.years", defaultMessage: "anni" }),
                        wildchartInfo: intl.formatMessage({ id: "pdf.scales.scaleGlycated.helpers.wildchartInfo", defaultMessage: "i valori contrassegnati sono stati stimati sulla base degli altri dati inseriti." }),
                    }
                    ,
                    info: {
                        usage: intl.formatMessage({ id: "pdf.scales.scaleGlycated.info.usage", defaultMessage: "Esplicitazione delle motivazioni del risultato sulla valutazione del rischio. Per uso soprattutto professionale (Medico)" }),
                        metrics: intl.formatMessage({ id: "pdf.scales.scaleGlycated.info.metrics", defaultMessage: "Il rischio è stato stimato sulla base delle seguenti linee guida: ESC Guidelines on cardiovascular disease prevention in clinical practice. Eur Heart J. 42, 3227–3337, 2021; SCORE2 risk prediction algorithms: new models to estimate 10-year risk of cardiovascular disease in Europe. Eur Heart J. 42, 2439–2454, 2021; SCORE2-OP risk prediction algorithms, Eur Heart J. 42, 2455–2467; ESC/EAS Guidelines for the management of dyslipidaemias Eur Heart J 41, 111-188, 2020; ESC/ESH Guidelines for the management of arterial hypertension Eur Heart J 39, 3021-3104, 2018." }),
                    }
                    ,
                }
                ,
                scaleEmo: {
                    result: {
                        title: intl.formatMessage({ id: "pdf.scales.scaleEmo.result.title", defaultMessage: "Esito" }),
                        subtitle: intl.formatMessage({ id: "pdf.scales.scaleEmo.result.subtitle", defaultMessage: "Il report è pronto!" }),
                        }}
                ,
                scaleUrine: {
                    result: {
                        title: intl.formatMessage({ id: "pdf.scales.scaleUrine.result.title", defaultMessage: "Esito" }),
                        subtitle: intl.formatMessage({ id: "pdf.scales.scaleUrine.result.subtitle", defaultMessage: "Il report è pronto!" }),
                        }}
                ,
            }
            ,
            footer: {
                page_indicator: intl.formatMessage({ id: "pdf.footer.page_indicator", defaultMessage: "pag." }),
            }
            ,
        }
        ,
        footer: {
            creators: intl.formatMessage({ id: "footer.creators", defaultMessage: "Sito progettato e sviluppato da AIDAPT S.r.l." }),
        }
        ,
        tables: {
            recent: {
                client: intl.formatMessage({ id: "tables.recent.client", defaultMessage: "Paziente" }),
                scale: intl.formatMessage({ id: "tables.recent.scale", defaultMessage: "Scala" }),
                date: intl.formatMessage({ id: "tables.recent.date", defaultMessage: "Data" }),
                hour: intl.formatMessage({ id: "tables.recent.hour", defaultMessage: "Ora" }),
                user: intl.formatMessage({ id: "tables.recent.user", defaultMessage: "Utente" }),
                noRowsLabel: intl.formatMessage({ id: "tables.recent.noRowsLabel", defaultMessage: "Nessuna compilazione effettuata" }),
            }
            ,
            client: {
                name: intl.formatMessage({ id: "tables.client.name", defaultMessage: "Nome" }),
                email: intl.formatMessage({ id: "tables.client.email", defaultMessage: "Email" }),
                scaleNumber: intl.formatMessage({ id: "tables.client.scaleNumber", defaultMessage: "Scale compilate" }),
                lastUpdate: intl.formatMessage({ id: "tables.client.lastUpdate", defaultMessage: "Ultima modifica" }),
                noRowsLabel: intl.formatMessage({ id: "tables.client.noRowsLabel", defaultMessage: "Nessun paziente registrato" }),
                userDataLabel: intl.formatMessage({ id: "tables.client.userDataLabel", defaultMessage: "Dati Paziente" }),
            }
            ,
            clientDetail: {
                name: intl.formatMessage({ id: "tables.clientDetail.name", defaultMessage: "Nome" }),
                surname: intl.formatMessage({ id: "tables.clientDetail.surname", defaultMessage: "Cognome" }),
                email: intl.formatMessage({ id: "tables.clientDetail.email", defaultMessage: "Email" }),
                dateOfBirth: intl.formatMessage({ id: "tables.clientDetail.dateOfBirth", defaultMessage: "Data di nascita" }),
                cityOfBirth: intl.formatMessage({ id: "tables.clientDetail.cityOfBirth", defaultMessage: "Città di nascita" }),
                provinceOfBirth: intl.formatMessage({ id: "tables.clientDetail.provinceOfBirth", defaultMessage: "Provincia di nascita" }),
                nationality: intl.formatMessage({ id: "tables.clientDetail.nationality", defaultMessage: "Nazionalità" }),
                taxCode: intl.formatMessage({ id: "tables.clientDetail.taxCode", defaultMessage: "Codice fiscale" }),


            }
            ,
            profileDetail: {
                name: intl.formatMessage({ id: "tables.profileDetail.name", defaultMessage: "Nome" }),
                email: intl.formatMessage({ id: "tables.profileDetail.email", defaultMessage: "Email" }),
                city: intl.formatMessage({ id: "tables.profileDetail.city", defaultMessage: "Città" }),
                address: intl.formatMessage({ id: "tables.profileDetail.address", defaultMessage: "Indirizzo" }),
                postCode: intl.formatMessage({ id: "tables.profileDetail.postCode", defaultMessage: "CAP" }),
                vatNumber: intl.formatMessage({ id: "tables.profileDetail.vatNumber", defaultMessage: "Partita Iva" }),
                phoneNumber: intl.formatMessage({ id: "tables.profileDetail.phoneNumber", defaultMessage: "Telefono" }),
            }
            ,
            user: {
                name: intl.formatMessage({ id: "tables.user.name", defaultMessage: "Nome" }),
                email: intl.formatMessage({ id: "tables.user.email", defaultMessage: "Email" }),
                role: intl.formatMessage({ id: "tables.user.role", defaultMessage: "Ruolo" }),
            }
            ,
            shared: {
                columnMenuSortAsc: intl.formatMessage({ id: "tables.shared.columnMenuSortAsc", defaultMessage: "Ordina ASC" }),
                columnMenuSortDesc: intl.formatMessage({ id: "tables.shared.columnMenuSortDesc", defaultMessage: "Ordina DESC" }),
                columnMenuUnsort: intl.formatMessage({ id: "tables.shared.columnMenuUnsort", defaultMessage: "Disordina" }),

                columnMenuFilter: intl.formatMessage({ id: "tables.shared.columnMenuFilter", defaultMessage: "Filtra" }),
                columnMenuHideColumn: intl.formatMessage({ id: "tables.shared.columnMenuHideColumn", defaultMessage: "Nascondi colonna" }),
                columnMenuManageColumns: intl.formatMessage({ id: "tables.shared.columnMenuManageColumns", defaultMessage: "Gestisci colonne" }),

                filterPanelColumns: intl.formatMessage({ id: "tables.shared.filterPanelColumns", defaultMessage: "Colonne" }),
                filterPanelInputLabel: intl.formatMessage({ id: "tables.shared.filterPanelInputLabel", defaultMessage: "Valore" }),
                filterPanelOperator: intl.formatMessage({ id: "tables.shared.filterPanelOperator", defaultMessage: "Operatore" }),
                filterPanelInputPlaceholder: intl.formatMessage({ id: "tables.shared.filterPanelInputPlaceholder", defaultMessage: "Valore" }),
                filterOperatorContains: intl.formatMessage({ id: "tables.shared.filterOperatorContains", defaultMessage: "Contiene" }),
                filterOperatorEquals: intl.formatMessage({ id: "tables.shared.filterOperatorEquals", defaultMessage: "Uguale" }),
                filterOperatorStartsWith: intl.formatMessage({ id: "tables.shared.filterOperatorStartsWith", defaultMessage: "Inizia con" }),
                filterOperatorEndsWith: intl.formatMessage({ id: "tables.shared.filterOperatorEndsWith", defaultMessage: "Finisce con" }),
                filterOperatorIsEmpty: intl.formatMessage({ id: "tables.shared.filterOperatorIsEmpty", defaultMessage: "E' vuoto" }),
                filterOperatorIsNotEmpty: intl.formatMessage({ id: "tables.shared.filterOperatorIsNotEmpty", defaultMessage: "Non è vuoto" }),
                filterOperatorIsAnyOf: intl.formatMessage({ id: "tables.shared.filterOperatorIsAnyOf", defaultMessage: "E' uno tra" }),

            }
        }
        ,
    }
        ;
    return tranlation_source;
};
