
import { Network } from "./network";


export interface Compilation {
    id?: string;
    client?: string;
    scale?: string;
    date?: string;
    hour?: string;
    pharmacist?: string;
    privateKey?: string;
    pharmacyId?: string;
    clientName?: string;
    clientSurname?: string;
    clientEmail?: string;
    scaleId?: string;
    scaleType?: string;
    userName?: string;
    userId?: string;
    submissionTime?: string
}

export interface ScaleInsertRequest<T, U> {
    scale: T & { scaleIndexes: U }
    compilation: Compilation
}

const returnScaleName = (type: string) => {
    if (type === "scalefree") {
        return "Scala Free"
    }
    else if (type === "scaleGlycated") {
        return "Scala Glycated"
    }
    else if (type === "scaleUrine") {
        return "Stick Urine"
    }
    else if (type === "scaleEmo") {
        return "Emoglobina Glicata"
    }
    else if (type === "lopreholder") {
        return "Lopre Holder"
    } else {
        return ""
    }
}

const returnDateByString = (date: string): { date: string, hour: string } => {

    const submissionDate: Date = new Date(date);

    const submissionHour: number = submissionDate.getHours();
    const submissionMinutes: number = submissionDate.getMinutes();
    let submissionMinutesString = submissionMinutes.toString();
    if (submissionMinutes < 10) {
        submissionMinutesString = "0" + submissionMinutesString;
    }

    const submissionDay: number = submissionDate.getDate();
    const submissionMonth: number = submissionDate.getMonth() + 1; // I mesi in JavaScript partono da 0
    const submissionYear: number = submissionDate.getFullYear();

    return {
        date: `${submissionDay}/${submissionMonth}/${submissionYear}`,
        hour: `${submissionHour}:${submissionMinutesString}`,
    }
}

export const getRecentCompilations = async (pharmacyId: string): Promise<Compilation[]> => {

    const data = await Network.Get(`/Compilation/${pharmacyId}`);

    const compilations: Compilation[] = data.map((item: any) => {

        const submissionDateHour = returnDateByString(item.submissionTime)
        //scale_id:item.scaleId,
        return {
            id: item.id,
            clientName: item.clientName,
            scale: returnScaleName(item.scaleType),
            date: submissionDateHour.date,
            hour: submissionDateHour.hour,
            pharmacist: item.userName
        };
    });
    return compilations;


};
export const getClientCompilations = async (pharmacyId: string, clientEmail: string): Promise<Compilation[]> => {

    const data = await Network.Get(`/Compilation/${pharmacyId}/client/${clientEmail}`);

    const compilations: Compilation[] = data.map((item: any) => {

        const submissionDateHour = returnDateByString(item.submissionTime)
        //scale_id:item.scaleId,
        return {
            id: item.id,
            client: item.clientName,
            scale: returnScaleName(item.scaleType),
            date: submissionDateHour.date,
            hour: submissionDateHour.hour,
            pharmacist: item.userName
        };
    });
    return compilations;


};


